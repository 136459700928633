<template>
  <div class="Contract-approveList">
    <list
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input label="合同编号" v-model="searchParams.contractNo" />
        <v-input label="合同名称" v-model="searchParams.contractName" />
        <v-select2 label="关联项目" v-model="searchParams.communityId" v-bind="communityParams" />
        <v-input :label="`关联${showOrgName}`" v-model="searchParams.orgName" />
        <v-input label="提交人" v-model="searchParams.inuser" />
        <v-datepicker
          label="提交时间"
          :maxDate="maxDate"
          type="rangedatetimer"
          :startTime.sync="searchParams.createStartTime"
          :endTime.sync="searchParams.createEndTime">
        </v-datepicker>
        <v-datepicker
          label="合同开始时间"
          :startTime.sync="searchParams.contractStartDateFrom"
          :endTime.sync="searchParams.contractStartDateTo">
        </v-datepicker>
        <v-datepicker
          label="合同结束时间"
          :startTime.sync="searchParams.contractEndDateForm"
          :endTime.sync="searchParams.contractEndDateTo">
        </v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="审核" type="text" @click="update(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { getListURL } from './api'
import { setStatusOps } from './map'
import moment from 'moment'
import { createHTMLVNode } from 'common/vdom'
export default {
  name: 'Contract-approveList',
  data () {
    let ORG_NAME = this.$store.state.baseConfig.docConfig.ORG_NAME
    return {
      searchUrl: getListURL,
      statusOps: setStatusOps(1),
      communityParams,
      maxDate: moment().format('YYYY-MM-DD HH:mm'),
      searchParams: {
        contractNo: '',
        contractStatus: 3,
        contractName: '',
        communityId: '',
        orgName: '',
        inuser: '',
        createStartTime: '',
        createEndTime: '',
        contractStartDateFrom: '',
        contractStartDateTo: '',
        contractEndDateForm: '',
        contractEndDateTo: '',
        approve: 1
      },
      headers: [
        {
          prop: 'contractNo',
          label: '合同编号'
        },
        {
          prop: 'contractName',
          label: '合同名称'
        },
        {
          prop: 'communityName',
          label: '关联项目'
        },
        {
          prop: 'orgName',
          label: `关联${ORG_NAME}`
        },
        {
          prop: 'houseNameVNode',
          label: '关联房号',
          formatter: (row, prop) => {
            let houseNameVNode = row.houseNameList && row.houseNameList.length ? row.houseNameList.join('<br>') : ''
            return createHTMLVNode(this, houseNameVNode)
          }
        },
        {
          prop: 'startDate',
          label: '合同开始时间'
        },
        {
          prop: 'endDate',
          label: '合同结束时间'
        },
        {
          prop: 'inuser',
          label: '提交人'
        },
        {
          prop: 'intime',
          label: '提交时间'
        }
      ]
    }
  },
  computed: {
    showOrgName () {
      return this.$store.state.baseConfig.docConfig.ORG_NAME || ''
    }
  },
  methods: {
    update (row) {
      this.$router.push({
        name: 'contractApproveForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
