import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 计费方式
const chargingType = [
  {
    text: '单价*房屋建筑面积㎡',
    value: 2
  },
  {
    text: '单价*房屋计费面积㎡',
    value: 4
  },
  {
    text: '单价*房屋建筑面积㎡/天',
    value: 5
  },
  {
    text: '单价*房屋计费面积㎡/天',
    value: 6
  },
  {
    text: '单价*用量（止度-起度）',
    value: 3
  },
  {
    text: '固定金额',
    value: 1
  }
]

const {
  map: chargingTypeMap,
  setOps: chargingTypeOps
} = mapHelper.setMap(chargingType)
export {
  statusMap,
  setStatusOps,
  chargingTypeMap,
  chargingTypeOps
}
