// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}chargeOrganizationContract/getChargeContractList`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}orderSettle/exportOrderSettle`

// 新增
const createURL = `${API_CONFIG.shopBaseURL}xxx`
// 编辑
const editURL = `${API_CONFIG.shopBaseURL}xxx`
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}chargeOrganizationContract/getChargeContractById`
// 上传
const uploadURL = `${API_CONFIG.baseURL}uploadAction!upload.action?module=shopgoods`
// 审批合同
const approveContractURL = `${API_CONFIG.butlerBaseURL}chargeOrganizationContract/approveContract`
// 预览pdf
const downloadPdf = `${API_CONFIG.butlerBaseURL}chargeOrganizationContract/downloadContract`

export {
  getListURL,
  exportListURL,
  createURL,
  editURL,
  queryURL,
  uploadURL,
  approveContractURL,
  downloadPdf
}
